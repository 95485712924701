import React from 'react';
import { Route } from 'react-router-dom';
import InjectLayout from 'components/Layout/InjectLayout';

import '../../styles/loggedout.scss';

const PublicRoute = props => {
  const { component: compToRender, layout, ...rest } = props;
  return (
    <Route
      {...rest}
      render={() => (
        <InjectLayout component={compToRender} layout={layout} {...props} />
      )}
    />
  );
};

export default PublicRoute;
