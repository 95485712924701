import createInjectableApiSaga from 'sagas/createApiSaga';
import {
  REGISTER_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_RESET_PASSWORD_TOKEN_DETAILS,
  GET_CUSTOMER_DETAILS,
  VERIFY_CUSTOMER_EMAIL,
  CHANGE_PASSWORD,
} from 'actions/resoluteUserActions';

export const registerUserSaga = createInjectableApiSaga(REGISTER_USER);
export const forgotPasswordSaga = createInjectableApiSaga(FORGOT_PASSWORD);
export const getResetPasswordTokenDetailsSaga = createInjectableApiSaga(
  GET_RESET_PASSWORD_TOKEN_DETAILS,
);
export const resetPasswordSaga = createInjectableApiSaga(RESET_PASSWORD);
export const getCustomerDetailsSaga = createInjectableApiSaga(
  GET_CUSTOMER_DETAILS,
);
export const verifyCustomerEmailSaga = createInjectableApiSaga(
  VERIFY_CUSTOMER_EMAIL,
);
export const changePasswordSaga = createInjectableApiSaga(CHANGE_PASSWORD);
