import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './dftoast.scss';

const DfToast = () => (
  <ToastContainer
    transition={Slide}
    // autoClose={3000}
    closeOnClick
    autoClose={false}
  />
);

export default DfToast;
