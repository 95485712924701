import { dispatch } from 'store';
import { actionTypes } from 'actions/actionTypes';
import { getSuccessType, getErrorType } from 'reducers/meta';

export const promptActon = success => {
  const action = {
    type: success
      ? getSuccessType(actionTypes.PROMPT)
      : getErrorType(actionTypes.PROMPT),
  };
  dispatch(action);
};
