import produce from 'immer';
import { SET_PAGE_PARAMS, REFRESH_PAGE } from 'actions/uiActions';

// The initial state of the App
export const initialState = {
  showDetails: false,
  pathParams: {},
  reducerPrefix: '',
  refresh: false,
};

/* eslint-disable default-case, no-param-reassign */
const uiStateReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_PAGE_PARAMS:
        draft.pathParams = action.pathParams;
        draft.reducerPrefix = action.reducerPrefix;
        break;

      case REFRESH_PAGE:
        draft.refresh = action.refresh;
        break;
    }
  });

export default uiStateReducer;
