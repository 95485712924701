import { useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { selectApiState } from 'reducers/selectors/apiSelectors';

import saga from 'sagas/signinSagas';
import userReducer from 'reducers/userReducer';

export default function useInjectSignIn() {
  const reducerKey = `sign_in`;
  useInjectSaga({ key: 'sign_in', saga });
  useInjectReducer({ key: reducerKey, reducer: userReducer });
  const customerState = useSelector(selectApiState(reducerKey));
  return customerState;
}
