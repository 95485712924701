import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';

import { SIGNOUT } from 'actions/userActions';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import userReducer from 'reducers/userReducer';
import uiStateReducer from 'reducers/uiStateReducer';

import { apiwatch } from 'reducers/apiwatchReducer';
import { prompt } from 'reducers/promptReducer';

export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    language: languageProviderReducer,
    router: connectRouter(history),
    user: userReducer,
    uiState: uiStateReducer,
    prompt,
    apiwatch,
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === SIGNOUT) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
    return appReducer(state, action);
  };

  return rootReducer;
}
