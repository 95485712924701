import { defineMessages } from 'react-intl';
const commonScope = 'common';

export default defineMessages({
  error: {
    id: `${commonScope}.error`,
    defaultMessage: 'Error',
  },
  register: {
    id: `${commonScope}.register`,
    defaultMessage: 'Register',
  },
  login: {
    id: `${commonScope}.login`,
    defaultMessage: 'Login',
  },
  email_placeholder: {
    id: `${commonScope}.email_placeholder`,
    defaultMessage: 'Email *',
  },
  password_placeholder: {
    id: `${commonScope}.password_placeholder`,
    defaultMessage: 'Password *',
  },
  forgot_password: {
    id: `${commonScope}.forgot_password`,
    defaultMessage: 'Forgot password?',
  },
  reset: {
    id: `${commonScope}.reset`,
    defaultMessage: 'Reset',
  },
  reset_password: {
    id: `${commonScope}.reset_password`,
    defaultMessage: 'Reset Password',
  },
  failed_to_login: {
    id: `${commonScope}.failed_to_login`,
    defaultMessage: 'Login Failed',
  },
  invalid_credentials: {
    id: `${commonScope}.invalid_credentials`,
    defaultMessage: 'Invalid credentials',
  },
  headline: {
    id: `${commonScope}.headline`,
    defaultMessage: 'Continuous Observability for Security and Compliance',
  },
  about_deepfactor: {
    id: `${commonScope}.about_deepfactor`,
    defaultMessage:
      'Security, performance and behavior monitoring in one simple solution. Find and fix runtime issues before deployment.',
  },
  back: {
    id: `${commonScope}.back`,
    defaultMessage: 'Back',
  },
  next: {
    id: `${commonScope}.next`,
    defaultMessage: 'Next',
  },
  save: {
    id: `${commonScope}.save`,
    defaultMessage: 'Save',
  },
  coming_soon: {
    id: `${commonScope}.coming_soon`,
    defaultMessage: 'Coming Soon!',
  },
  coming_soon_text: {
    id: `${commonScope}.coming_soon_text`,
    defaultMessage:
      'We are working very hard to enable operator-like visibility to software development teams! Stay tuned for exciting updates!',
  },
  yes: {
    id: `${commonScope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${commonScope}.no`,
    defaultMessage: 'No',
  },
  table_zero_message: {
    id: `${commonScope}.table_zero_message`,
    defaultMessage: 'No data matching current filter selection',
  },
  view_details: {
    id: `${commonScope}.view_details`,
    defaultMessage: 'View Details',
  },
  view_all: {
    id: `${commonScope}.view_all`,
    defaultMessage: 'View All',
  },
  browser_size_not_supported: {
    id: `${commonScope}.browser_size_not_supported`,
    defaultMessage: 'Deepfactor awesomeness needs more space!',
  },
  browser_size_not_supported_subtitle: {
    id: `${commonScope}.browser_size_not_supported_subtitle`,
    defaultMessage:
      'Please switch to a tablet or laptop and maximise the browser',
  },
  admin: {
    id: `${commonScope}.admin`,
    defaultMessage: 'Admin',
  },
  firstname: {
    id: `${commonScope}.firstname`,
    defaultMessage: 'First Name',
  },
  lastname: {
    id: `${commonScope}.lastname`,
    defaultMessage: 'Last Name',
  },
  first_name: {
    id: `${commonScope}.first_name`,
    defaultMessage: 'First Name',
  },
  last_name: {
    id: `${commonScope}.last_name`,
    defaultMessage: 'Last Name',
  },
  email: {
    id: `${commonScope}.email`,
    defaultMessage: 'Email',
  },
  company_name: {
    id: `${commonScope}.company_name`,
    defaultMessage: 'Company Name',
  },
  instances: {
    id: `${commonScope}.instances`,
    defaultMessage: 'Instances',
  },
  password: {
    id: `${commonScope}.password`,
    defaultMessage: 'Password',
  },
  current_password: {
    id: `${commonScope}.current_password`,
    defaultMessage: 'Current Password',
  },
  confirm_password: {
    id: `${commonScope}.confirm_password`,
    defaultMessage: 'Confirm Password',
  },
  new_password: {
    id: `${commonScope}.new_password`,
    defaultMessage: 'New Password',
  },
  confirm_new_password: {
    id: `${commonScope}.confirm_new_password`,
    defaultMessage: 'Confirm New Password',
  },
  change_password: {
    id: `${commonScope}.change_password`,
    defaultMessage: 'Change Password',
  },
  reset_password_link_expired: {
    id: `${commonScope}.reset_password_link_expired`,
    defaultMessage: 'Expired Token',
  },
  reset_password_link_expired_description: {
    id: `${commonScope}.reset_password_link_expired_description`,
    defaultMessage: 'Please request a new password reset link',
  },
  licenses: {
    id: `${commonScope}.licenses`,
    defaultMessage: 'Licenses',
  },
  license_type: {
    id: `${commonScope}.license_type`,
    defaultMessage: 'License Type',
  },
  valid_from: {
    id: `${commonScope}.valid_from`,
    defaultMessage: 'Valid From',
  },
  valid_to: {
    id: `${commonScope}.valid_to`,
    defaultMessage: 'Valid To',
  },
  user_limit: {
    id: `${commonScope}.user_limit`,
    defaultMessage: 'User Limit',
  },
  profile: {
    id: `${commonScope}.profile`,
    defaultMessage: 'Profile',
  },
  portal_token: {
    id: `${commonScope}.portal_token`,
    defaultMessage: 'Portal Token',
  },
  copied: {
    id: `${commonScope}.copied`,
    defaultMessage: 'Copied',
  },
  active: {
    id: `${commonScope}.active`,
    defaultMessage: 'Active',
  },
  send: {
    id: `${commonScope}.send`,
    defaultMessage: 'Send',
  },
  cft: {
    id: `${commonScope}.cft`,
    defaultMessage: 'CFT',
  },
  cloud_formation_template: {
    id: `${commonScope}.cloud_formation_template`,
    defaultMessage: 'Cloud Formation Template',
  },
  download: {
    id: `${commonScope}.download`,
    defaultMessage: 'Download',
  },
  download_cft: {
    id: `${commonScope}.download_cft`,
    defaultMessage: 'Download CFT',
  },
  download_ova: {
    id: `${commonScope}.download_ova`,
    defaultMessage: 'Download OVA',
  },
  portal_instances: {
    id: `${commonScope}.portal_instances`,
    defaultMessage: 'Portal Instances',
  },
  instance_key: {
    id: `${commonScope}.instance_key`,
    defaultMessage: 'Instance Key',
  },
  created: {
    id: `${commonScope}.created`,
    defaultMessage: 'Created',
  },
  verify_email: {
    id: `${commonScope}.verify_email`,
    defaultMessage: 'Verify Email',
  },
  email_verification_success_title: {
    id: `${commonScope}.email_verification_success_title`,
    defaultMessage: 'Email Verification Successfull!',
  },
  email_verification_success_description: {
    id: `${commonScope}.email_verification_success_description`,
    defaultMessage: 'Your email has been successfully verified',
  },
  email_verification_error_title: {
    id: `${commonScope}.email_verification_error_title`,
    defaultMessage: 'Email Verification Failed!',
  },
  email_verification_error_description: {
    id: `${commonScope}.email_verification_error_description`,
    defaultMessage: 'Your email verification was unsuccessfull.',
  },
  get_started: {
    id: `${commonScope}.get_started`,
    defaultMessage: 'Get Started',
  },
  install_deepfactor: {
    id: `${commonScope}.install_deepfactor`,
    defaultMessage: 'Install Deepfactor Portal',
  },
  welcome_message: {
    id: `${commonScope}.welcome_message`,
    defaultMessage: 'Welcome! We are stoked to have you with us!',
  },
  install_deepfactor_description: {
    id: `${commonScope}.install_deepfactor_description`,
    defaultMessage:
      'Please install Deepfactor Portal in your environment to unlock Continuous Observability for Security and Compliance',
  },
  cft_description: {
    id: `${commonScope}.cft_description`,
    defaultMessage:
      '1. Download the CloudFormation Template (CFT). Please do not share the CFT outside your organization. It has your Deepfactor portal token embedded in it.',
  },
  cft_help_doc: {
    id: `${commonScope}.cft_help_doc`,
    defaultMessage:
      '2. Click here for instructions to setup using the downloaded CFT',
  },
  ova_description: {
    id: `${commonScope}.ova_description`,
    defaultMessage: '1. Download the OVA & optionally the checksum file',
  },
  ova_help_doc: {
    id: `${commonScope}.ova_help_doc`,
    defaultMessage:
      '2. Click here for instructions to setup using the downloaded OVA',
  },
  here: {
    id: `${commonScope}.here`,
    defaultMessage: 'here',
  },
  download_key: {
    id: `${commonScope}.download_key`,
    defaultMessage: 'Download Key',
  },
  portal: {
    id: `${commonScope}.portal`,
    defaultMessage: 'Portal',
  },
  my_portals: {
    id: `${commonScope}.my_portals`,
    defaultMessage: 'My Portals',
  },
  activated: {
    id: `${commonScope}.activated`,
    defaultMessage: 'Activated',
  },
  logout: {
    id: `${commonScope}.logout`,
    defaultMessage: 'Logout',
  },
  my_profile: {
    id: `${commonScope}.my_profile`,
    defaultMessage: 'My Profile',
  },
  already_have_an_account: {
    id: `${commonScope}.already_have_an_account`,
    defaultMessage: 'Already have an acount? Login Now',
  },
  dont_have_an_account: {
    id: `${commonScope}.dont_have_an_account`,
    defaultMessage: "Don't have an account? Register Now",
  },
  note: {
    id: `${commonScope}.note`,
    defaultMessage: 'Note',
  },
  feedback: {
    id: `${commonScope}.feedback`,
    defaultMessage: 'Feedback',
  },
  docs: {
    id: `${commonScope}.docs`,
    defaultMessage: 'Docs',
  },
  edition: {
    id: `${commonScope}.edition`,
    defaultMessage: 'Edition',
  },
  upgrade_license: {
    id: `${commonScope}.upgrade_license`,
    defaultMessage: 'Upgrade License',
  },
  upgrade_license_request_message: {
    id: `${commonScope}.upgrade_license_request_message`,
    defaultMessage:
      'We will be happy to guide you through the license upgrade process. Please provide the contact information below to allow us to contact you.',
  },
  upgrade_license_request_success: {
    id: `${commonScope}.upgrade_license_request_success`,
    defaultMessage:
      'Thank you. We are delighted to receive your request and one of our representatives will get in touch with you shortly.',
  },
  phone_number_optional: {
    id: `${commonScope}.phone_number_optional`,
    defaultMessage: 'Phone Number (Optional)',
  },
  message_optional: {
    id: `${commonScope}.message_optional`,
    defaultMessage: 'Message (Optional)',
  },
  submit: {
    id: `${commonScope}.submit`,
    defaultMessage: 'Submit',
  },
  request_submitted: {
    id: `${commonScope}.request_submitted`,
    defaultMessage: 'Request Submitted Successfully!',
  },
  settings: {
    id: `${commonScope}.settings`,
    defaultMessage: 'Settings',
  },
  change_password_success: {
    id: `${commonScope}.change_password_success`,
    defaultMessage: 'Your password has been changed successfully!',
  },
  change_password_error: {
    id: `${commonScope}.change_password_error`,
    defaultMessage:
      'There was an error changing your password, please try again!',
  },
  change: {
    id: `${commonScope}.change`,
    defaultMessage: 'Change',
  },
  deepfactor_brand_text: {
    id: `${commonScope}.deepfactor_brand_text`,
    defaultMessage:
      'Get started with Deepfactor today and immediately reap the benefits of leveraging developer security for secure and compliant cloud native apps.',
  },
  deepfactor_brand_text_2: {
    id: `${commonScope}.deepfactor_brand_text_2`,
    defaultMessage:
      'Enable engineering teams to create secure and compliant apps on schedule as part of their day-to-day tasks using a developer-centric, purpose-built, runtime observability tool.{br}Deepfactor leverages powerful insight modules such as syscall risks, data risks, behavior risks, DAST scans, a software bill of materials (SBOM), and vulnerable dependencies to create high-fidelity alerts with actionable evidence.',
  },
  deepfactor_brand_subtext_1: {
    id: `${commonScope}.deepfactor_brand_subtext_1`,
    defaultMessage: 'Observe billions of application events at runtime',
  },
  deepfactor_brand_subtext_2: {
    id: `${commonScope}.deepfactor_brand_subtext_2`,
    defaultMessage:
      'Quick installation on any cloud (AWS or VMware or your K8s cluster)',
  },
  deepfactor_brand_subtext_3: {
    id: `${commonScope}.deepfactor_brand_subtext_3`,
    defaultMessage:
      'Easy to setup with zero code changes utilizing the command line workflow',
  },
  deepfactor_brand_subtext_4: {
    id: `${commonScope}.deepfactor_brand_subtext_4`,
    defaultMessage:
      'Bring any workload (Kubernetes apps, Docker containers, and non-containerized apps)',
  },
  deepfactor_brand_subtext_5: {
    id: `${commonScope}.deepfactor_brand_subtext_5`,
    defaultMessage: 'Out-of-the box integrations: Jenkins, Slack, and Jira',
  },
  deepfactor_brand_subtext_6: {
    id: `${commonScope}.deepfactor_brand_subtext_6`,
    defaultMessage: 'Plugs into your CI/CD pipeline',
  },
  deepfactor_brand_subtext_7: {
    id: `${commonScope}.deepfactor_brand_subtext_7`,
    defaultMessage: 'Language agnostic & minimal performance overhead',
  },
  verify_email_message: {
    id: `${commonScope}.verify_email_message`,
    defaultMessage:
      'You have still not verified your email address. Please click on the verify link in the email sent by Deepfactor. Please ensure to look in your spam folder if you are unable to locate the email.',
  },
  password_reset_in_progress: {
    id: `${commonScope}.password_reset_in_progress`,
    defaultMessage: 'Password reset is in progress.',
  },
  download_checksum: {
    id: `${commonScope}.download_checksum`,
    defaultMessage: 'Download Checksum',
  },
  vmware_token_explanation: {
    id: `${commonScope}.vmware_token_explanation`,
    defaultMessage: '3. Enter the above portal token during setup',
  },
  k8s_help_doc: {
    id: `${commonScope}.k8s_help_doc`,
    defaultMessage:
      '1. Click here for instructions to setup Deepfactor Portal on AWS EKS',
  },
  k8s_token_explanation: {
    id: `${commonScope}.k8s_token_explanation`,
    defaultMessage:
      "2. Enter the above portal token as value for 'portalToken' key in override.yaml along with other values",
  },
  portal_token_description: {
    id: `${commonScope}.portal_token_description`,
    defaultMessage:
      'Please install Deepfactor Portal in your environment to unlock Continuous Observability for Security and Compliance. During the installation, you will prompted to enter the following Deepfactor portal token. Please do not share this outside your organization.',
  },
  aws_eks: {
    id: `${commonScope}.aws_eks`,
    defaultMessage: 'AWS EKS',
  },
  deepfactor: {
    id: `${commonScope}.deepfactor`,
    defaultMessage: 'Deepfactor',
  },
});
