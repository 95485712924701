import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useInjectSaga } from 'utils/injectSaga';

import LoadingIndicator from 'components/LoadingIndicator';

// Layout
import LoggedOutLayout from 'components/Layout/LoggedOutLayout';
import LoggedInLayout from 'components/Layout/LoggedInLayout';

// Components
import DfToast from 'components/ui/DfToast';
import DfPrompt from 'components/ui/DfPrompt/DfPrompt';
import HomePage from 'containers/HomePage/Loadable';
import Signin from 'containers/Signin/Loadable';
import Register from 'containers/Register/Loadable';
import NotFoundPage from 'containers/NotFound/Loadable';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import Verify from 'containers/Verify/Loadable';
import MyProfile from 'containers/MyProfile/Loadable';
import GetStarted from 'containers/GetStarted/Loadable';
import MyPortals from 'containers/MyPortals/Loadable';
import UpgradeLicense from 'containers/UpgradeLicense/Loadable';
import ChangePassword from 'containers/ChangePassword/Loadable';

import UserSaga from 'sagas/userSaga';
import { makeSelectInitialLoadUserDone } from 'reducers/selectors/userSelectors';
import { selectPrompt } from 'reducers/selectors/promptSelectors';
import { loadUserFromLocalStorage } from 'actions/userActions';
import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';

// Styles
import 'styles/reset.scss';
import 'styles/index.scss';
import 'styles/modal.scss';

const initialLoadUserDoneSelector = makeSelectInitialLoadUserDone();

const App = () => {
  useInjectSaga({ key: 'user', saga: UserSaga });
  const dispatch = useDispatch();
  const initialLoadUserDone = useSelector(initialLoadUserDoneSelector);
  const prompt = useSelector(selectPrompt);

  useEffect(() => {
    dispatch(loadUserFromLocalStorage());
  }, [dispatch]);

  return (
    <>
      <div className="app-wrapper resolute-app-wrapper d-flex flex-column justify-content-start align-items-center">
        <DfToast />
        {prompt.show && <DfPrompt {...prompt} />}
        <>
          {!initialLoadUserDone ? (
            <LoadingIndicator />
          ) : (
            <Switch>
              <Route exact path="/" component={HomePage} />
              <PublicRoute
                path="/login"
                layout={LoggedOutLayout}
                component={Signin}
              />
              <PublicRoute
                path="/forgot_password"
                layout={LoggedOutLayout}
                component={ForgotPassword}
              />
              <PublicRoute
                path="/forgot"
                layout={LoggedOutLayout}
                component={ResetPassword}
              />
              <PublicRoute
                path="/register"
                layout={LoggedOutLayout}
                component={Register}
              />
              <PublicRoute
                path="/verify"
                layout={LoggedOutLayout}
                component={Verify}
              />
              <PrivateRoute
                path="/my-profile"
                exact
                layout={LoggedInLayout}
                component={MyProfile}
              />
              <PrivateRoute
                path="/change-password"
                exact
                layout={LoggedInLayout}
                component={ChangePassword}
              />
              <PrivateRoute
                path="/get-started"
                exact
                layout={LoggedInLayout}
                component={GetStarted}
              />
              <PrivateRoute
                path="/my-portals"
                exact
                layout={LoggedInLayout}
                component={MyPortals}
              />
              <PrivateRoute
                path="/upgrade-license"
                exact
                layout={LoggedInLayout}
                component={UpgradeLicense}
              />
              <Route component={NotFoundPage} />
            </Switch>
          )}
        </>
      </div>
    </>
  );
};

export default App;
