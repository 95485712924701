import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

export default function MobileHamburger({ isNavbarOpen, handleNavbarOpen }) {
  const closeIconClass = isNavbarOpen
    ? 'device-icon device-icon-active'
    : 'device-icon';
  const openIconClass = isNavbarOpen
    ? 'device-icon'
    : 'device-icon device-icon-active';
  return (
    <div className="mobile-header-link-actions">
      <CloseIcon
        className={closeIconClass}
        onClick={() => handleNavbarOpen(false)}
      />
      <MenuRoundedIcon
        className={openIconClass}
        onClick={() => handleNavbarOpen(true)}
      />
    </div>
  );
}
