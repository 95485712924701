import produce from 'immer';
import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  SIGNOUT,
  INITIAL_LOAD_USER_DONE,
} from 'actions/userActions';

export const initialState = {
  initialLoadUserDone: false,
  fetching: false,
  signingIn: false,
  signinSuccess: false,
  signinFailed: false,
  statusText: null,
  role: 'normal',
  email: '',
  fullName: '',
  isAuthenticated: false,
};

/* eslint-disable default-case, no-param-reassign */
const userReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, draft => {
    let newState;
    switch (action.type) {
      case INITIAL_LOAD_USER_DONE:
        draft.initialLoadUserDone = true;
        if (action.user) {
          Object.assign(draft, action.user);
        }
        break;

      case SIGNIN:
        draft.signingIn = true;
        draft.signinSuccess = false;
        draft.signinFailed = false;
        break;

      case SIGNIN_SUCCESS:
        draft.signingIn = false;
        draft.signinSuccess = true;
        draft.signinFailed = false;
        // Add all properties of the signin response to the root level of state
        Object.assign(draft, action.user);
        break;

      case SIGNIN_FAILED:
        draft.signingIn = false;
        draft.signinSuccess = false;
        draft.signinFailed = true;
        draft.statusText = action.statusText;
        Object.assign(draft, action.error);
        break;

      case SIGNOUT:
        newState = { ...initialState };
        newState.initialLoadUserDone = true;
        return newState;
    }
  });
export default userReducer;
