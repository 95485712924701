/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from 'assets/svgs/DfWhiteLogo.svg';
import commonMessages from 'containers/LanguageProvider/commonMessages';

// Components
import NavigationLink from 'components/NavigationLink';
import MobileMenu from './MobileMenu';

const LoggedOutLayout = props => {
  const [isNavbarOpen, handleNavbarOpen] = useState(false);

  const closeNavbar = () => {
    handleNavbarOpen(false);
  };

  const navbarClass = isNavbarOpen ? `df-navbar-open` : '';
  return (
    <>
      <nav className="header-container d-flex flex-row justify-content-between align-items-center">
        <NavigationLink className="header-logo" to="/">
          <Logo width="180px" height="34px" />
        </NavigationLink>
        <div className={`header-links-actions ${navbarClass}`}>
          <ol className="pages-link-container" onClick={closeNavbar}>
            <li className="page-link-container">
              <NavigationLink className="page-link-item" to="/login">
                <FormattedMessage {...commonMessages.login} />
              </NavigationLink>
            </li>
            <li className="page-link-container">
              <NavigationLink className="page-link-item" to="/register">
                <FormattedMessage {...commonMessages.register} />
              </NavigationLink>
            </li>
          </ol>
        </div>

        <MobileMenu
          isNavbarOpen={isNavbarOpen}
          handleNavbarOpen={handleNavbarOpen}
        />
      </nav>
      {props.children}
    </>
  );
};

export default LoggedOutLayout;
