export function storeLocally(itemName, itemValue) {
  localStorage.setItem(itemName, JSON.stringify(itemValue));
}

// eslint-disable-next-line consistent-return
export function getJsonFromLocalStorage(key) {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}
