import queryString from 'query-string';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// Utils
import { storeLocally } from 'utils/localStorage';
import makeApiRequest from 'utils/apiRequest';

// Actions
import {
  SIGNIN,
  SIGNIN_SUCCESS,
  signinSuccess,
  signinFailed,
} from 'actions/userActions';

function* signin(action) {
  const { response, error } = yield call(makeApiRequest, action.config);
  if (response && response.data) {
    const { customer } = response.data;
    const successResp = {
      ...customer,
      isAuthenticated: true,
    };
    storeLocally('user', successResp);
    yield put(signinSuccess(successResp));
  } else if (error) {
    yield put(signinFailed(error));
  } else {
    yield put(
      signinFailed({
        error: {
          email: 'Invalid credentials',
        },
      }),
    );
  }
}

function* goToHome() {
  const queryParams = queryString.parse(window.location.search);
  if (queryParams.next) {
    yield put(push(decodeURIComponent(queryParams.next)));
  } else {
    yield put(push('/'));
  }
}

export default function* signinSagas() {
  yield all([takeLatest(SIGNIN, signin), takeLatest(SIGNIN_SUCCESS, goToHome)]);
}
