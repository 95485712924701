export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILED = 'SIGNIN_FAILURE';
export const SIGNOUT = 'SIGNOUT';
export const LOAD_USER_FROM_LOCAL_STORAGE = 'LOAD_USER_FROM_LOCAL_STORAGE';
export const INITIAL_LOAD_USER_DONE = 'INITIAL_LOAD_USER_DONE';

export function signout() {
  return { type: SIGNOUT };
}

export function loadUserFromLocalStorage() {
  return { type: LOAD_USER_FROM_LOCAL_STORAGE };
}

export function initialLoadUserDone(user) {
  return { type: INITIAL_LOAD_USER_DONE, user };
}

export function signin(data) {
  return {
    type: SIGNIN,
    config: {
      method: 'post',
      url: '/customers/login',
      data,
    },
  };
}

export function signinSuccess(user) {
  return {
    type: SIGNIN_SUCCESS,
    user,
  };
}

export function signinFailed(error) {
  return {
    type: SIGNIN_FAILED,
    error,
  };
}
