import React from 'react';
import { useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';

// Actions
import { signout } from 'actions/userActions';

const onIdle = dispatch => {
  dispatch(signout());
};

const IdleTimerComponent = () => {
  const dispatch = useDispatch();
  return (
    <IdleTimer
      element={document}
      onIdle={() => onIdle(dispatch)}
      debounce={250}
      timeout={1000 * 60 * 60}
    />
  );
};

export default IdleTimerComponent;
