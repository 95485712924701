import React, { useEffect } from 'react';
import queryString from 'query-string';

import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { selectApiState } from 'reducers/selectors/apiSelectors';

// Actions
import { getResetPasswordTokenDetails } from 'actions/resoluteUserActions';
import { getResetPasswordTokenDetailsSaga } from 'sagas/resoluteUserSaga';
import { getResetPasswordTokenDetailsReducer } from 'reducers/resoluteUserReducer';

import commonMessages from 'containers/LanguageProvider/commonMessages';

// Components
import DfBrand from 'components/DfBrand';
import DfHelmet from 'components/ui/DfHelmet';
import DfLoader from 'components/ui/DfLoader';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordExpired from './ResetPasswordExpired';

import './index.scss';

const useInjectSagaReducers = () => {
  useInjectSaga({
    key: 'reset_password_token',
    saga: getResetPasswordTokenDetailsSaga,
  });
  useInjectReducer({
    key: 'reset_password_token',
    reducer: getResetPasswordTokenDetailsReducer,
  });
};

export default function RegisterInvitedUser(props) {
  const intl = useIntl();
  const query = queryString.parse(props.location.search);
  const { email, key } = query;
  const data = {
    customer: {
      email,
      key,
    },
  };

  const dispatch = useDispatch();
  useInjectSagaReducers();

  useEffect(() => {
    dispatch(getResetPasswordTokenDetails(data));
  }, [dispatch]);

  const resetPasswordTokenState = useSelector(
    selectApiState('reset_password_token'),
  );
  const resetPasswordData = resetPasswordTokenState.data
    ? resetPasswordTokenState.data
    : {};

  if (resetPasswordTokenState.fetching) return <DfLoader isLoading />;

  return (
    <div className="page-wrapper d-flex flex-column justify-content-center align-items-center">
      <DfHelmet title={intl.formatMessage(commonMessages.reset_password)} />

      {resetPasswordTokenState.signingIn && <DfLoader isLoading />}
      <div className="page-content d-flex flex-row justify-content-start align-items-center">
        <DfBrand />
        <div className="loggedout-form-container">
          {resetPasswordData.msg === 'verified' ? (
            <ResetPasswordForm email={email} pwdKey={key} />
          ) : (
            <ResetPasswordExpired />
          )}
        </div>
      </div>
    </div>
  );
}
