const simpleActionTypes = {
  ISFETCHING: 'ISFETCHING',
  API_WATCH: 'API_WATCH',
  PROMPT: 'PROMPT',
};

export const compositeActionTypes = {
  COMPOSITE_ACTION: 'COMPOSITE_ACTION',
};

export const actionTypes = {
  ...simpleActionTypes,
  ...compositeActionTypes,
};
