import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshPage } from 'actions/uiActions';
import { getRefresh } from 'reducers/selectors/uiStateSelectors';

import './header.scss';

import DfLoader from 'components/ui/DfLoader';

const RefreshComp = () => {
  const dispatch = useDispatch();
  setTimeout(() => {
    dispatch(refreshPage(false));
  }, 1000);
  return <DfLoader />;
};

const InjectLayout = props => {
  const { component: Component, layout: Layout, ...rest } = props;
  const { refresh } = useSelector(getRefresh);

  if (refresh) {
    return (
      <Layout pathname={props.location.pathname}>
        <RefreshComp />
      </Layout>
    );
  }

  return Layout ? (
    <Layout pathname={props.location.pathname}>
      <Component {...rest} />
    </Layout>
  ) : (
    <Component {...rest} />
  );
};

export default InjectLayout;
