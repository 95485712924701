import { put, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  getJsonFromLocalStorage,
  removeFromLocalStorage,
} from 'utils/localStorage';

import {
  SIGNOUT,
  LOAD_USER_FROM_LOCAL_STORAGE,
  initialLoadUserDone,
} from 'actions/userActions';

function* signout() {
  removeFromLocalStorage('user');
  yield put(push('/login'));
}

function* loadUserFromLocalStorage() {
  const localUser = getJsonFromLocalStorage('user');
  yield put(initialLoadUserDone(localUser));
}

export default function* userSagas() {
  yield all([
    takeLatest(SIGNOUT, signout),
    takeLatest(LOAD_USER_FROM_LOCAL_STORAGE, loadUserFromLocalStorage),
  ]);
}
