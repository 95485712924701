/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from 'reducers/selectors/userSelectors';
import commonMessages from 'containers/LanguageProvider/commonMessages';

import useInjectSignIn from 'hooks/useInjectSignIn';
import { signout } from 'actions/userActions';

// Components
import { ReactComponent as Logo } from 'assets/svgs/DfWhiteLogo.svg';
import NavigationLink from 'components/NavigationLink';
import UserDropdown from 'components/Header/UserDropdown';
import MobileMenu from './MobileMenu';

const LoggedInLayout = props => {
  const dispatch = useDispatch();
  useInjectSignIn();

  const [isNavbarOpen, handleNavbarOpen] = useState(false);

  const closeNavbar = () => {
    handleNavbarOpen(false);
  };

  const onHandleLogout = () => {
    dispatch(signout());
  };

  const navbarClass = isNavbarOpen ? `df-navbar-open` : '';
  const user = useSelector(userSelector);
  return (
    <>
      <nav className="header-container d-flex flex-row justify-content-between align-items-center">
        <NavigationLink className="header-logo" to="/get-started">
          <Logo width="180px" height="34px" />
        </NavigationLink>

        <div className={`header-links-actions ${navbarClass}`}>
          <ol className="pages-link-container" onClick={closeNavbar}>
            <li className="page-link-container">
              <NavigationLink className="page-link-item" to="/get-started">
                <FormattedMessage {...commonMessages.get_started} />
              </NavigationLink>
            </li>
            <li className="page-link-container">
              <NavigationLink className="page-link-item" to="/my-portals">
                <FormattedMessage {...commonMessages.my_portals} />
              </NavigationLink>
            </li>
            <li className="page-link-container">
              <NavigationLink className="page-link-item" to="/upgrade-license">
                <FormattedMessage {...commonMessages.upgrade_license} />
              </NavigationLink>
            </li>
            <li className="page-link-container">
              <a
                className="page-link-item"
                href="https://docs.deepfactor.io/hc/en-us/requests/new"
                target="_blank"
              >
                <FormattedMessage {...commonMessages.feedback} />
              </a>
            </li>
            <li className="page-link-container">
              <a
                className="page-link-item"
                href="https://docs.deepfactor.io/"
                target="_blank"
              >
                <FormattedMessage {...commonMessages.docs} />
              </a>
            </li>

            {/* USER DROPDOWN */}
            <li className="page-link-container df-smaller-device">
              <NavigationLink className="page-link-item" to="/my-profile">
                <FormattedMessage {...commonMessages.my_profile} />
              </NavigationLink>
            </li>
            <li className="page-link-container df-smaller-device">
              <NavigationLink className="page-link-item" to="/change-password">
                <FormattedMessage {...commonMessages.change_password} />
              </NavigationLink>
            </li>
            <li className="page-link-container df-smaller-device">
              <button
                type="button"
                className="page-link-item"
                onClick={onHandleLogout}
              >
                <FormattedMessage {...commonMessages.logout} />
              </button>
            </li>
          </ol>
          <div className="header-user-container">
            <UserDropdown user={user} onHandleLogout={onHandleLogout} />
          </div>
        </div>

        <MobileMenu
          isNavbarOpen={isNavbarOpen}
          handleNavbarOpen={handleNavbarOpen}
        />
      </nav>

      {props.children}
    </>
  );
};

export default LoggedInLayout;
