import axios from 'axios';
import { getJsonFromLocalStorage } from 'utils/localStorage';
import { BASE_API_URL } from '../config';

const getHeaders = () => {
  const header = {
    ...getCustomerIDHeader(),
    'X-Requested-With': 'XMLHttpRequest',
    ...getAuthHeader(),
  };
  return header;
};

const getAuthHeader = () => {
  const user = getJsonFromLocalStorage('user');
  if (user) {
    return {
      Authorization: `Bearer ${user.token}`,
    };
  }
  return {};
};

const getCustomerIDHeader = () => {
  const customerId = localStorage.getItem('customerid');
  if (customerId) {
    return {
      customerid: customerId,
    };
  }
  return {};
};

const axiosInstance = () =>
  axios.create({
    baseURL: BASE_API_URL,
    time0ut: 10000,
    headers: getHeaders(),
    withCredentials: false,
  });

export default function makeApiRequest(config) {
  return axiosInstance()
    .request(config)
    .then(response => ({ response }))
    .catch(error => ({ response: {}, error }));
}
