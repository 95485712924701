export const SET_PAGE_PARAMS = 'uiActions/SET_PAGE_PARAMS';
export const REFRESH_PAGE = 'uiActions/REFRESH_PAGE';

export function setPageParams(pathParams, reducerPrefix) {
  return {
    type: SET_PAGE_PARAMS,
    pathParams,
    reducerPrefix,
  };
}

export function refreshPage(refresh) {
  return {
    type: REFRESH_PAGE,
    refresh,
  };
}
