import React from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import commonMessages from 'containers/LanguageProvider/commonMessages';

export default function ResetPasswordExpired() {
  return (
    <div className="reset-password-link-expired">
      <h2 className="section-title">
        <FormattedMessage {...commonMessages.reset_password_link_expired} />
      </h2>
      <div className="d-flex flex-column">
        <p className="link-expired-message">
          <FormattedMessage
            {...commonMessages.reset_password_link_expired_description}
          />
        </p>
        <div className="d-flex justify-content-center">
          <Link className="action-btn btn btn-primary" to="/forgot_password">
            <FormattedMessage {...commonMessages.reset_password} />
          </Link>
        </div>
      </div>
    </div>
  );
}
