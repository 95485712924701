import React from 'react';
import { getUserInitials, getUserFullName } from 'utils/userUtils';
// import { ReactComponent as ProfileIcon } from 'assets/svgs/Profile-replace.svg';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import './userAvatar.scss';

export default function UserAvatar({
  user,
  className = '',
  allowInitials = true,
}) {
  const finalClassName = `user-avatar ${className}`;
  const fullName = getUserFullName(user);
  const userInitials = getUserInitials(fullName);
  if (user.profilePicUrl) {
    return (
      <img
        className={`${finalClassName} user-pic`}
        alt="profile"
        src={user.profilePicUrl}
        width="40px"
        height="40px"
      />
    );
  }
  if (allowInitials) {
    return (
      <div
        className={`${finalClassName} user-initials d-flex flex-row justify-content-center align-items-center`}
      >
        {userInitials}
      </div>
    );
  }
  return <AccountCircleIcon className={className} />;
}
