import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';

import { promiseListener } from 'configureStore';
import { getSuccessType, getErrorType } from 'reducers/meta';
import { renderPasswordField } from 'components/ui/ReduxFormElements';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';

// Actions
import { RESET_PASSWORD, resetPassword } from 'actions/resoluteUserActions';
import { resetPasswordSaga } from 'sagas/resoluteUserSaga';
import { resetPasswordReducer } from 'reducers/resoluteUserReducer';

import commonMessages from 'containers/LanguageProvider/commonMessages';

import { passwordsMatch, passwordRule, required } from 'utils/validation';
import { composeValidators } from 'utils/validators';

const useInjectSagaReducers = () => {
  useInjectSaga({
    key: 'reset_password',
    saga: resetPasswordSaga,
  });
  useInjectReducer({
    key: 'reset_password',
    reducer: resetPasswordReducer,
  });
};

const getFormSubmitError = action => {
  const { error } = action;
  if (error) {
    return {
      [FORM_ERROR]: <FormattedMessage {...commonMessages.error} />,
    };
  }
  return null;
};

export default function ResetPasswordForm({ email, pwdKey }) {
  const intl = useIntl();
  useInjectSagaReducers();
  const onSubmitWrapper = onSubmit => values => {
    const pwdData = {
      customer: {
        email,
        key: pwdKey,
        new_password: values.password,
      },
    };
    const actionPayload = resetPassword(pwdData);
    return new Promise(resolve => {
      onSubmit(actionPayload).then(
        success => {
          resolve(success);
        },
        error => {
          resolve(error);
        },
      );
    });
  };

  const baseAction = RESET_PASSWORD;

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={baseAction}
      resolve={getSuccessType(baseAction)}
      reject={getErrorType(baseAction)}
      setPayload={(action, payload) => ({ ...action, ...payload })}
      getError={action => getFormSubmitError(action)}
      getPayload={action => action}
    >
      {onSubmit => (
        <Form
          onSubmit={onSubmitWrapper(onSubmit)}
          validate={values => {
            const errors = {};
            if (values.confirm_password) {
              errors.confirm_password = passwordsMatch(
                values.confirm_password,
                values,
              );
            }
            return errors;
          }}
          render={({ submitError, handleSubmit, submitting }) => (
            <form className="loggedout-form" onSubmit={handleSubmit}>
              <h2 className="section-title">
                <FormattedMessage {...commonMessages.reset_password} />
              </h2>
              <div className="df-form d-flex flex-column">
                <Field
                  initialValue=""
                  name="password"
                  placeholder={`${intl.formatMessage(
                    commonMessages.password,
                  )}*`}
                  component={renderPasswordField}
                  validate={composeValidators(required, passwordRule)}
                />
                <Field
                  initialValue=""
                  name="confirm_password"
                  placeholder={`${intl.formatMessage(
                    commonMessages.confirm_password,
                  )}*`}
                  component={renderPasswordField}
                  validate={required}
                />
              </div>
              {submitError && (
                <div className="form-submit-error">{submitError}</div>
              )}
              <div className="actions-container d-flex flex-row justify-content-between align-items-center">
                <button
                  className="action-btn btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  <FormattedMessage {...commonMessages.reset} />
                </button>
              </div>
            </form>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
}
