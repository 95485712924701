/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
import { getRequestType, getSuccessType, getErrorType } from 'reducers/meta';
import isEqual from 'lodash.isequal';

export const defaultInitialState = {
  fetching: false,
  fetchFailed: false,
  fetched: false,
  pathParams: {},
  sortBy: {},
  filter: {},
};

export default function apiReducerCreator({
  baseAction,
  initialState = defaultInitialState,
}) {
  const composedInitialState = { ...defaultInitialState, ...initialState };
  const requestAction = getRequestType(baseAction);
  const successAction = getSuccessType(baseAction);
  const errorAction = getErrorType(baseAction);
  return (state = composedInitialState, action) =>
    // eslint-disable-next-line consistent-return
    produce(state, draft => {
      const {
        sortBy,
        filter,
        pathParams = {} /* pageSize, pageNum */,
      } = action;
      // If path params don't match, ignore
      const originalPathParams = original(draft.pathParams);
      if (!isEqual(originalPathParams, pathParams)) {
        return draft;
      }
      // eslint-disable-next-line default-case
      switch (action.type) {
        case requestAction:
          // If filter, sort or pageSize parameters have changed, return to initial state
          /* if(!isEqual(draft.filter, filter) ||
              !isEqual(draft.sortBy, sortBy)) {
            draft.data = undefined;
          } */
          draft.fetching = true;
          draft.fetched = false;
          draft.fetchFailed = false;
          draft.sortBy = sortBy;
          draft.filter = filter;
          draft.pathParams = pathParams;
          break;
        case successAction:
          draft.fetching = false;
          draft.fetched = true;
          draft.fetchFailed = false;
          draft.data = action.data;
          break;
        case errorAction:
          draft.fetching = false;
          draft.fetched = false;
          draft.fetchFailed = true;
          draft.error = action.error;
          break;
      }
    });
}
