import { put, take, takeEvery } from 'redux-saga/effects';
import { getSuccessType, getErrorType } from 'reducers/meta';

export function createCompositeSaga() {
  // eslint-disable-next-line func-names
  return function*(type) {
    yield takeEvery(type, compositeSagaFn);
  };
}

export function* compositeSagaFn(action) {
  yield put({ ...action.action1 });
  const result = yield take([
    getSuccessType(action.action1.type),
    getErrorType(action.action1.type),
  ]);
  if (result.type === getSuccessType(action.action1.type)) {
    yield put({ ...action.action2 });
  }
}
