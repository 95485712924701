import React from 'react';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { promptActon } from 'actions/promptActions';

import commonMessages from 'containers/LanguageProvider/commonMessages';

const DfPrompt = props => {
  const {
    title,
    body,
    negetiveButtonText,
    negetiveButtonClass,
    positiveButtonText,
    positiveButtonClass,
  } = props;
  const closeModal = () => promptActon(false);
  const negBtnClass = negetiveButtonClass || 'action-btn btn btn-negative';
  const posBtnClass = positiveButtonClass || 'action-btn btn btn-primary';
  const negBtnText = negetiveButtonText || (
    <FormattedMessage {...commonMessages.no} />
  );
  const posBtnText = positiveButtonText || (
    <FormattedMessage {...commonMessages.yes} />
  );
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={closeModal}
      className="custom-dialog-container"
    >
      <AppBar className="custom-dialog-top-bar">
        <Toolbar className="custom-dialog-toolbar d-flex flex-row justify-content-between">
          {title}
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="custom-dialog-form">
        <div
          className="prompt-body"
          style={{ fontSize: '13px', color: '#393c40', lineHeight: 1.2 }}
        >
          {body}
        </div>
        <div className="actions-container d-flex flex-row justify-content-center align-items-center">
          <button className={negBtnClass} type="button" onClick={closeModal}>
            {negBtnText}
          </button>
          <button
            className={posBtnClass}
            type="button"
            onClick={() => promptActon(true)}
          >
            {posBtnText}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DfPrompt;
