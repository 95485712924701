export function getUserFullName(user) {
  let { fullName } = user;
  if (!fullName) {
    fullName = user.firstname;
    if (user.lastname) {
      fullName = `${fullName} ${user.lastname}`;
    }
  }
  return fullName.toLowerCase();
}
export function getUserInitials(fullName) {
  return fullName
    .split(' ')
    .filter(t => t)
    .map(namePart => namePart[0].toUpperCase())
    .join('');
}

export function checkIfAdmin(user) {
  return user && (user.role === 'C_ADMIN' || user.role === 'DF_ADMIN');
}

export function checkIfAdminOrDemoUser(user) {
  const isAdmin = checkIfAdmin(user);
  const isDemoUser = checkIfDemoUser(user);
  return isAdmin || isDemoUser;
}

export function checkIfReadOnlyUser(user) {
  return user.role === 'C_READ_ONLY';
}

export function checkIfDemoUser(user) {
  return user.demo_user;
}

export function checkIfNonDemoReadOnlyUser(user) {
  const isDemoUser = checkIfDemoUser(user);
  const isReadOnlyUser = checkIfReadOnlyUser(user);
  return isReadOnlyUser && !isDemoUser;
}
