/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeSelectIsAuthenticated } from 'reducers/selectors/userSelectors';

// Components
import InjectLayout from 'components/Layout/InjectLayout';
import IdleTimer from 'components/IdleTimer';

const isAuthenticatedSelector = makeSelectIsAuthenticated();

const PrivateRoute = ({ component: compToRender, layout, ...rest }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  let nextUri = '/';
  if (!isAuthenticated) {
    const { location } = rest.history;
    nextUri = encodeURIComponent(`${location.pathname}${location.search}`);
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <>
            <IdleTimer />
            <InjectLayout component={compToRender} layout={layout} {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: `next=${nextUri}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
