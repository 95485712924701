import { createSelector } from 'reselect';
import { initialState } from 'reducers/userReducer';

export const selectUser = state => {
  const returnValue = state.user || initialState;
  return returnValue;
};

export const makeSelectUserToken = () => state => state.user.token;

export const makeSelectIsAuthenticated = () => state =>
  state.user.isAuthenticated;

export const makeSelectInitialLoadUserDone = () => state =>
  state.user.initialLoadUserDone;

export const userSelector = createSelector(selectUser, userState => ({
  authToken: userState.token,
  email: userState.email,
  fullName: userState.full_name,
  isAuthenticated: userState.isAuthenticated,
}));
