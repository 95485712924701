/* eslint-disable consistent-return */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const scope = 'common';

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export function validateEmail(value) {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!value || !emailRegex.test(value)) {
    return <FormattedMessage id={`${scope}.email_valiation_error`} />;
  }
}

export function validatePassword(value) {
  if (!value || value.length < 4 || value.length > 20) {
    return <FormattedMessage id={`${scope}.password_validation_error`} />;
  }
}
