import { REGISTER_USER } from 'actions/resoluteUserActions';

const userObject = {
  data: {
    firstname: 'John',
    lastname: 'Doe',
  },
};

const actionResponseMap = {
  [REGISTER_USER]: userObject,
};

export default actionResponseMap;
