import { actionTypes } from 'actions/actionTypes';

const { API_WATCH } = actionTypes;

const reducerIncrementor = () => (state = {}, action) => {
  if (action.type === API_WATCH) {
    const temp = state[action.apikey] ? state[action.apikey] : 0;
    return {
      ...state,
      [action.apikey]: temp + 1,
    };
  }
  return state;
};

export const apiwatch = reducerIncrementor();
