import React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'containers/LanguageProvider/commonMessages';

export const REGISTER_USER = 'REGISTER_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const GET_RESET_PASSWORD_TOKEN_DETAILS =
  'GET_RESET_PASSWORD_TOKEN_DETAILS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const VERIFY_CUSTOMER_EMAIL = 'VERIFY_CUSTOMER_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const registerUserAction = data => ({
  type: REGISTER_USER,
  config: {
    method: 'post',
    url: '/customers/',
    data,
  },
  route: '/login',
  toast: {
    success: <FormattedMessage {...commonMessages.verify_email_message} />,
    error: 'Error',
  },
});

export const forgotPassword = data => ({
  type: FORGOT_PASSWORD,
  config: {
    method: 'post',
    url: '/customers/forgot',
    data,
  },
  route: '/login',
  toast: {
    success:
      'If the provided email is a registered email ID on Deepfactor, you will receive an email with a reset password link.',
    error: 'Error',
  },
});

export const getResetPasswordTokenDetails = data => ({
  type: GET_RESET_PASSWORD_TOKEN_DETAILS,
  config: {
    method: 'post',
    url: '/customers/forgot-verify',
    data,
  },
  blockLogout: true,
});

export const resetPassword = data => ({
  type: RESET_PASSWORD,
  config: {
    method: 'post',
    url: '/customers/forgot-reset',
    data,
  },
  route: '/login',
  toast: {
    success: 'Your password has been reset successfully!',
    error: 'Error',
  },
  blockLogout: true,
});

export const getCustomerDetails = () => ({
  type: GET_CUSTOMER_DETAILS,
  config: {
    method: 'get',
    url: '/customer',
  },
});

export const verifyCustomerEmail = (email, key) => ({
  type: VERIFY_CUSTOMER_EMAIL,
  config: {
    method: 'post',
    url: '/verify',
    data: {
      customer: {
        email,
        key,
      },
    },
  },
});

export const changePassword = data => ({
  type: CHANGE_PASSWORD,
  config: {
    method: 'post',
    url: '/customer/change-password',
    data,
  },
  blockLogout: true,
});
