/* eslint-disable no-console */
import { call, put, takeEvery, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import apiMocks from 'apimocks';

import makeApiRequest from 'utils/apiRequest';
import { getRequestType, getSuccessType, getErrorType } from 'reducers/meta';

import { actionTypes } from 'actions/actionTypes';
import { signout } from 'actions/userActions';

const { API_WATCH, PROMPT } = actionTypes;

export function createApiSaga() {
  // eslint-disable-next-line func-names
  return function*(type) {
    yield takeEvery(type, apiSaga);
  };
}

export default function createInjectableApiSaga(type) {
  // eslint-disable-next-line func-names
  return function*() {
    yield takeEvery(type, apiSaga);
  };
}

export function* apiSaga(action) {
  let continueWithSaga = true;
  if (action.prompt) {
    yield put({ type: PROMPT, prompt: action.prompt });
    const promptResponseAction = yield take([
      getSuccessType(PROMPT),
      getErrorType(PROMPT),
    ]);
    if (promptResponseAction.type === getErrorType(PROMPT)) {
      continueWithSaga = false;
    }
  }
  if (continueWithSaga) {
    const { config } = action;
    const requestAction = getRequestType(action.type);
    const successAction = getSuccessType(action.type);
    const errorAction = getErrorType(action.type);
    if (config) {
      yield put({ ...action, type: requestAction, isFetching: true });
      yield put({ type: 'ISFETCHING', key: action.type, isFetching: true });
      if (config.data) {
        const { meta, ...rest } = config.data;
        config.data = rest;
      }

      console.log(
        'Calling api with url ',
        config.url,
        ' is mocked',
        config.mock,
      );
      let response;
      let error;
      if (config.mock) {
        response = {
          status: 200,
          data: {
            data: apiMocks[action.type],
          },
        };
      } else {
        const apiResponse = yield call(makeApiRequest, config);
        response = apiResponse.response;
        error = apiResponse.error;
      }

      let successCalled = false;

      if (response.status >= 200 && response.status < 300) {
        yield put({
          ...action,
          type: successAction,
          data: response.data,
          isFetching: false,
        });
        yield put({
          type: 'ISFETCHING',
          key: action.type,
          isFetching: false,
        });
        if (action.toast) {
          toast.success(action.toast.success);
        }
        yield put({
          type: API_WATCH,
          apikey: action.type,
        });
        if (action.route) {
          yield put(push(action.route));
        }
        successCalled = true;
      } else if (error) {
        console.log(
          `api ${config.url} from config ${JSON.stringify(
            config,
          )} returned error`,
          error,
        );

        yield put({
          ...action,
          type: errorAction,
          error,
          isFetching: false,
        });

        if (error.response) {
          const { status } = error.response;
          if (error.response.data && error.response.data.toast) {
            const t = error.response.data.toast;
            toast[t.function](t.message);
          } else if (status === 403 || status === 401) {
            if (!action.blockLogout) {
              console.log('Got 401 / 403 response ', status, ' logging out');
              yield put(signout());
            }
          }
        }
      }

      if (!successCalled) {
        const errorBody = error && error.response && error.response.data;
        yield put({
          ...action,
          error: errorBody,
          type: errorAction,
          isFetching: true,
        });
        yield put({ type: 'ISFETCHING', key: action.type, isFetching: false });
      }
    }
  }
}
