export const required = value =>
  value && value.trim() ? undefined : 'Required';

export const minLength = max => value =>
  value && value.length < max ? `Must be ${max} characters or more` : undefined;

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const number = value =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined;

export const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined;

export const passwordRule = value =>
  value && !/^(?=.*[0-9])\S{6,}$/.test(value)
    ? 'Password must be at least 6 characters and must include at least one number'
    : undefined;

export const passwordsMatch = (value, allValues) =>
  value !== allValues.password ? "Passwords don't match" : undefined;

export const phoneNumber = value =>
  // value &&
  // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)
  value && value.match(/\d/g).length === 10 ? undefined : 'Wrong Phone number';

export const isInt = n => Number(n) === n && n % 1 === 0;

export const isFloat = n => Number(n) === n && n % 1 !== 0;

export const isNotNumber = value =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(value) ? undefined : 'Value should not be a number';
