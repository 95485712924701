import { createMuiTheme } from '@material-ui/core/styles';

const dfTheme = createMuiTheme({
  typography: {
    fontFamily: ['Inter', 'Source Sans Pro', 'Roboto', 'Arial'].join(','),
    fontSize: 12,
  },
  palette: {
    primary: {
      main: '#048aef',
    },
    secondary: {
      main: '#ff944e',
    },
  },
});
export default dfTheme;
