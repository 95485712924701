import { actionTypes } from 'actions/actionTypes';
import { getSuccessType, getErrorType } from './meta';

const { PROMPT } = actionTypes;

const promptReducer = ({
  type = PROMPT,
  initialState = {
    show: false,
  },
}) => (state = initialState, action) => {
  switch (action.type) {
    case type: {
      return {
        ...state,
        ...action.prompt,
        show: true,
      };
    }
    case getSuccessType(type):
    case getErrorType(type):
      return {
        show: false,
      };
    default:
      return state;
  }
};

export const prompt = promptReducer({});
