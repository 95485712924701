import apiReducerCreator from 'reducers/meta/apiReducerCreator';
import {
  REGISTER_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_RESET_PASSWORD_TOKEN_DETAILS,
  GET_CUSTOMER_DETAILS,
  VERIFY_CUSTOMER_EMAIL,
  CHANGE_PASSWORD,
} from 'actions/resoluteUserActions';

export const registerUserReducer = apiReducerCreator({
  baseAction: REGISTER_USER,
});

export const forgotPasswordReducer = apiReducerCreator({
  baseAction: FORGOT_PASSWORD,
});

export const getResetPasswordTokenDetailsReducer = apiReducerCreator({
  baseAction: GET_RESET_PASSWORD_TOKEN_DETAILS,
});

export const resetPasswordReducer = apiReducerCreator({
  baseAction: RESET_PASSWORD,
});

export const getCustomerDetatilsReducer = apiReducerCreator({
  baseAction: GET_CUSTOMER_DETAILS,
});

export const verifyCustomerEmailReducer = apiReducerCreator({
  baseAction: VERIFY_CUSTOMER_EMAIL,
});

export const changePasswordReducer = apiReducerCreator({
  baseAction: CHANGE_PASSWORD,
});
