import React from 'react';

import './index.scss';

const renderInputField = props => {
  const {
    input,
    placeholder,
    label,
    type,
    className,
    meta: { touched, error, submitError },
  } = props;
  const classNameDiv = className ? ` ${className}` : '';
  let errorClass = '';
  if (touched) {
    if (error || submitError) {
      errorClass = 'is-invalid';
    } else {
      errorClass = 'is-valid';
    }
  }
  return (
    <div className={classNameDiv}>
      <div className="input-container">
        <label>{label}</label>
        <input
          placeholder={placeholder}
          {...input}
          className={`form-control ${errorClass}`}
          type={type}
        />
        {touched && (error || submitError) && (
          <div className="invalid-feedback">{error || submitError}</div>
        )}
      </div>
    </div>
  );
};

const renderPasswordField = props => {
  const {
    input,
    label,
    placeholder,
    className,
    meta: { touched, error, submitError },
  } = props;
  const classNameDiv = className ? ` ${className}` : '';
  let errorClass = '';
  if (touched) {
    if (error || submitError) {
      errorClass = 'is-invalid';
    } else {
      errorClass = 'is-valid';
    }
  }
  return (
    <div className={classNameDiv}>
      <div className="input-container">
        <label>{label}</label>
        <input
          placeholder={placeholder}
          {...input}
          className={`form-control ${errorClass}`}
          type="password"
        />
        {touched && (error || submitError) && (
          <div className="invalid-feedback">{error || submitError}</div>
        )}
      </div>
    </div>
  );
};

const renderDropdownField = props => {
  const {
    input,
    placeholder,
    options,
    className,
    valuekey,
    labelkey,
    meta: { touched, error },
  } = props;
  const classNameDiv = className ? ` ${className}` : '';
  let errorClass = '';
  if (touched) {
    if (error) {
      errorClass = 'is-invalid';
    } else {
      errorClass = 'is-valid';
    }
  }
  let selectOptions = [];
  if (options) {
    selectOptions = options.map((option, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <option key={i} value={option[valuekey]}>
        {option[labelkey]}
      </option>
    ));
  }
  return (
    <div className={classNameDiv}>
      <div className="input-container">
        <select {...input} className={`form-control ${errorClass}`}>
          <option value="">{placeholder}</option>
          {selectOptions}
        </select>
        {touched && error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

const renderTextArea = props => {
  const {
    input,
    placeholder,
    type,
    className,
    rows,
    columns,
    meta: { touched, error },
  } = props;
  const classNameDiv = className ? ` ${className}` : '';
  let errorClass = '';
  if (touched) {
    if (error) {
      errorClass = 'is-invalid';
    } else {
      errorClass = 'is-valid';
    }
  }
  return (
    <div className={classNameDiv}>
      <div className="input-container">
        <textarea
          placeholder={placeholder}
          {...input}
          className={`form-control ${errorClass}`}
          type={type}
          rows={rows}
          columns={columns}
        />
        {touched && error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

const renderPhoneNumberField = props => {
  const {
    input,
    placeholder,
    className,
    meta: { touched, error },
  } = props;
  const classNameDiv = className ? ` ${className}` : '';
  let errorClass = '';
  if (touched) {
    if (error) {
      errorClass = 'is-invalid';
    } else {
      errorClass = 'is-valid';
    }
  }
  return (
    <div className={classNameDiv}>
      <div className="input-container">
        <input
          placeholder={placeholder}
          {...input}
          className={`form-control ${errorClass}`}
          type="text"
        />
        {touched && error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

export {
  renderInputField,
  renderPasswordField,
  renderDropdownField,
  renderTextArea,
  renderPhoneNumberField,
};
