import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
export default history;

export function pushStateToExistingLocation(currentHistory, location, state) {
  currentHistory.push(
    {
      pathname: location.pathname,
      search: location.search,
    },
    state,
  );
}
