import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DFIconBlackOrangePlain } from 'assets/svgs/DFIconBlackOrangePlain.svg';

import commonMessages from 'containers/LanguageProvider/commonMessages';

import './index.scss';

export default function DfBrand() {
  return (
    <div className="company-summary-container">
      <div className="df-brand-logo">
        <DFIconBlackOrangePlain width="90" height="90" />
      </div>

      <div className="company-summary">
        <div className="company-headline">
          <FormattedMessage
            {...commonMessages.deepfactor_brand_text}
            values={{ br: <br /> }}
          />
        </div>
        <div className="company-headline-2">
          <FormattedMessage
            {...commonMessages.deepfactor_brand_text_2}
            values={{ br: <br /> }}
          />
        </div>
        <div className="summary">
          <ul>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_1}
              />
            </li>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_2}
              />
            </li>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_3}
              />
            </li>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_4}
              />
            </li>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_5}
              />
            </li>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_6}
              />
            </li>
            <li>
              <FormattedMessage
                {...commonMessages.deepfactor_brand_subtext_7}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
