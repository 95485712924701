import React from 'react';
import { FORM_ERROR } from 'final-form';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from 'configureStore';
import { getSuccessType, getErrorType } from 'reducers/meta';
import { renderInputField } from 'components/ui/ReduxFormElements';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';

// Actions
import { FORGOT_PASSWORD, forgotPassword } from 'actions/resoluteUserActions';
import { forgotPasswordSaga } from 'sagas/resoluteUserSaga';
import { forgotPasswordReducer } from 'reducers/resoluteUserReducer';

import commonMessages from 'containers/LanguageProvider/commonMessages';
import { required, email } from 'utils/validation';
import { composeValidators } from 'utils/validators';
import { getErrorStatus } from 'utils/errorHandlers';

const useInjectSagaReducers = () => {
  useInjectSaga({
    key: 'forgot_password',
    saga: forgotPasswordSaga,
  });
  useInjectReducer({
    key: 'forgot_password',
    reducer: forgotPasswordReducer,
  });
};

const getFormSubmitError = action => {
  const { error } = action;
  if (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === 409) {
      return {
        [FORM_ERROR]: (
          <FormattedMessage {...commonMessages.password_reset_in_progress} />
        ),
      };
    }
    return {
      [FORM_ERROR]: <FormattedMessage {...commonMessages.error} />,
    };
  }
  return null;
};

export default function ForgotPasswordForm() {
  const intl = useIntl();
  const dispatch = useDispatch();
  useInjectSagaReducers();
  const onSubmitWrapper = onSubmit => values => {
    const data = {
      customer: {
        email: values.email,
      },
    };
    const actionPayload = forgotPassword(data);
    return new Promise(resolve => {
      onSubmit(actionPayload).then(
        success => {
          resolve(success);
        },
        error => {
          resolve(error);
        },
      );
    });
  };

  const baseAction = FORGOT_PASSWORD;

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={baseAction}
      resolve={getSuccessType(baseAction)}
      reject={getErrorType(baseAction)}
      setPayload={(action, payload) => ({ ...action, ...payload })}
      getError={action => getFormSubmitError(action)}
      getPayload={action => action}
    >
      {onSubmit => (
        <Form
          onSubmit={onSubmitWrapper(onSubmit)}
          render={({ submitError, handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="loggedout-form">
              <h2 className="section-title">
                <FormattedMessage {...commonMessages.forgot_password} />
              </h2>
              <div className="df-form d-flex flex-column">
                <Field
                  initialValue=""
                  name="email"
                  placeholder={intl.formatMessage(
                    commonMessages.email_placeholder,
                  )}
                  component={renderInputField}
                  type="text"
                  variant="outlined"
                  validate={composeValidators(required, email)}
                />
              </div>

              {submitError && (
                <div className="form-submit-error">{submitError}</div>
              )}
              <div className="actions-container d-flex flex-row justify-content-center ">
                <button
                  type="button"
                  className="action-btn btn btn-secondary"
                  onClick={() => dispatch(goBack())}
                >
                  <FormattedMessage {...commonMessages.back} />
                </button>
                <button
                  className="action-btn btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  <FormattedMessage {...commonMessages.send} />
                </button>
              </div>
            </form>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
}
