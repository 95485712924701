import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as ProfileIcon } from 'assets/svgs/Profile-replace.svg';
import NavigationLink from 'components/NavigationLink';
import UserAvatar from 'components/UserAvatar';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'containers/LanguageProvider/commonMessages';

const useStyles = makeStyles(theme => ({
  typography: {
    minWidth: '240px',
    maxWidth: '300px',
  },
  userDropdownHeader: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  userPic: {
    color: '#6c757d',
    marginRight: '15px',
  },
  userDropdownItem: {
    fontSize: '14px',
    display: 'flex',
    background: 'none',
    border: 'none',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f8f9fa',
      textDecoration: 'none',
    },
    cursor: 'pointer',
    color: '#048aef !important',
  },
  userName: {
    lineHeight: 1.2,
    fontWeight: 'bold',
    marginBottom: '8px',
    textTransform: 'capitalize',
    color: '#6c757d',
  },
  userEmail: {
    fontSize: '12px',
    lineHeight: 1.2,
    color: '#6c757d',
  },
}));

const UserDropdown = ({ user, onHandleLogout }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const name = user.fullName ? user.fullName : '';
  const email = user.email ? user.email : '';

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <button
        type="button"
        className="btn btn-bare"
        onClick={e => {
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <UserAvatar user={user} className="me" />
      </button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.userDropdownHeader}>
          <div className={classes.userPic}>
            <ProfileIcon width="40px" height="40px" />
          </div>
          <div className="user-details">
            <div className={classes.userName}>{name}</div>
            <div className={classes.userEmail}>{email}</div>
          </div>
        </div>
        <Divider />
        <MenuItem onClick={handleClose}>
          <NavigationLink to="/my-profile" className={classes.userDropdownItem}>
            <FormattedMessage {...commonMessages.my_profile} />
          </NavigationLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavigationLink
            to="/change-password"
            className={classes.userDropdownItem}
          >
            <FormattedMessage {...commonMessages.change_password} />
          </NavigationLink>
        </MenuItem>
        <MenuItem>
          <button
            type="button"
            className={classes.userDropdownItem}
            onClick={onHandleLogout}
          >
            <FormattedMessage {...commonMessages.logout} />
          </button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
