export const getErrorStatus = error => {
  const status =
    error && error.response && error.response.status
      ? error.response.status
      : '';
  return status;
};

export function formErrorWrapper(action) {
  if (
    action.error &&
    action.error.response &&
    action.error.response.data &&
    action.error.response.data.errors
  ) {
    const formErrors = action.error.response.data.errors;
    return formErrors;
  }
  return {};
}
