export const selectReducerPrefix = state => state.uiState.reducerPrefix;

export const selectPageParams = state => ({
  reducerPrefix: state.uiState.reducerPrefix,
  pathParams: state.uiState.pathParams,
});

export const getRefresh = state => ({
  refresh: state.uiState.refresh,
});
