import React from 'react';
import { ReactComponent as Spinner } from 'assets/svgs/Spinner.svg';

import './dfloader.scss';

const DfLoader = ({ isLoading, graph, table, btnSpinner, width, height }) => {
  if (isLoading) {
    const spinnerWidth = width || '60px';
    const spinnerHeight = height || '60px';
    if (graph) {
      return (
        <div className="details-table-container d-flex flex-row justify-content-start">
          <div className="table-wrapper">
            <div className="df-loader-table">
              <Spinner width={spinnerWidth} height={spinnerHeight} />
            </div>
          </div>
        </div>
      );
    }

    if (btnSpinner) return <Spinner width="10px" height="10px" />;

    if (table) {
      return (
        <div className="df-loader-table">
          <Spinner width={spinnerWidth} height={spinnerHeight} />
        </div>
      );
    }

    return (
      <div className="df-loader-overlay d-flex flex-column justify-content-center align-items-center">
        <Spinner width={spinnerWidth} height={spinnerHeight} />
      </div>
    );
  }

  return null;
};

export default DfLoader;
