import React from 'react';
import { useIntl } from 'react-intl';

import commonMessages from 'containers/LanguageProvider/commonMessages';

// Components
import DfHelmet from 'components/ui/DfHelmet';
import DfBrand from 'components/DfBrand';
import ForgotPasswordForm from './ForgotPasswordForm';

export default function ResetPassword() {
  const intl = useIntl();
  return (
    <div className="page-wrapper d-flex flex-column justify-content-start align-items-center">
      <DfHelmet title={intl.formatMessage(commonMessages.forgot_password)} />
      <div className="page-content d-flex flex-row justify-content-start align-items-center">
        <DfBrand />
        <div className="loggedout-form-container">
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
}
